var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12 mt-5"
  }, [_c('el-table', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "data": _vm.tableData,
      "header-cell-style": {
        background: 'rgba(212, 216, 226, 0.2)'
      },
      "empty-text": 'No Data Available',
      "height": "40vh"
    }
  }, [_c('el-table-column', {
    attrs: {
      "label": "Transactions",
      "prop": "Transactions"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "Amount",
      "prop": "Amount"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "Head About Us",
      "prop": "heard_about_us"
    }
  })], 1)], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };