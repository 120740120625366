var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-sm-11 col-md-4 col-lg-5 mt-5"
  }, [_c('div', {
    staticClass: "card px-3 py-5"
  }, [_c('h2', {
    staticClass: "text-center"
  }, [_vm._v("Number of Customers (" + _vm._s(_vm.getCriteria) + ")")]), _c('p', {
    staticClass: "text-center"
  }, [_vm._v(_vm._s(_vm.responseData.value))])])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };