export default {
  props: {
    responseData: {
      required: true,
      type: Object
    }
  },
  computed: {
    getCriteria() {
      switch (this.responseData.criteria) {
        case "leads_monthly":
          return "Leads Monthly";
        case "top_ups_sms":
          return "SMS Topups";
        case "top_ups_voice":
          return "Voice Topups";
        case "sum_top_ups_sms":
          return "Sum SMS Topups";
        case "sum_top_ups_voice":
          return "Sum Voice Topups";
        case "revenue":
          return "Top Up Voice";
        case "dormant_accounts":
          return "Dormant Accounts ";
        case "revenue_generated":
          return "Monthly New Users ";
        case "new_users_top_ups":
          return "Monthly New TopUps";
        case "deactivated_accounts":
          return "Deactivated Accounts";
      }
    }
  }
};