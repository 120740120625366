import DynamicTable from "./dynamicTable";
import BusinessChasis from "./BusinessChasis";
import store from "@/state/store.js";
export default {
  props: {
    criterias: {
      type: Array,
      required: true
    },
    action: {
      type: String,
      required: true
    },
    viewType: {
      required: true,
      type: String
    }
  },
  components: {
    DynamicTable,
    BusinessChasis
  },
  data() {
    return {
      tableLoading: false,
      total: 0,
      pageSize: 1,
      loading: false,
      tableData: [],
      dataCount: 0,
      formData: {
        criteria: "",
        date_from: "",
        date_to: ""
      },
      rules: {
        criteria: [{
          required: true,
          message: "Criteria is required",
          trigger: ["change", "blur"]
        }],
        date_from: [{
          required: true,
          message: "Date from is required",
          trigger: ["change", "blur"]
        }],
        date_to: [{
          required: true,
          message: "Date to is required",
          trigger: ["change", "blur"]
        }]
      },
      displayData: '',
      businessChasis: []
    };
  },
  methods: {
    view(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.tableLoading = true;
          store.dispatch("admin/reporting/" + this.action, this.formData).then(response => {
            if (this.viewType === "business_chasis") {
              // Find criteria from criterias array (prop)
              const criteria = this.criterias.find(criteria => criteria.value === this.formData.criteria);
              // If criteria needs to show table put in 'response.data' and have an early return else put in only the count
              if (criteria.showTable == false) {
                this.dataCount = response;
                return;
              }
              this.tableData = (response === null || response === void 0 ? void 0 : response.data) || [];
              this.total = response.total;
              this.pageSize = response.per_page;
            } else {
              this.tableData = response;
            }
          }).catch(err => {
            var _err$response;
            if ((err === null || err === void 0 || (_err$response = err.response) === null || _err$response === void 0 ? void 0 : _err$response.data) == undefined) {
              this.errors = "Network error try again";
            }
          }).finally(() => this.tableLoading = false);
        } else {
          return false;
        }
      });
    },
    handleCurrentChange(val) {
      this.tableLoading = true;
      store.dispatch("admin/reporting/" + this.action, {
        ...this.formData,
        page: val
      }).then(response => {
        this.tableData = response.data || [];
        this.total = response.total;
        this.pageSize = response.per_page;
      }).catch(err => {
        var _err$response2;
        if ((err === null || err === void 0 || (_err$response2 = err.response) === null || _err$response2 === void 0 ? void 0 : _err$response2.data) == undefined) {
          this.errors = "Network error try again";
        }
      }).finally(() => this.tableLoading = false);
    }
  },
  computed: {
    displayedData() {
      return this.tableData || [];
    },
    checkIfShowTable() {
      const criteria = this.criterias.find(criteria => criteria.value === this.formData.criteria);
      if (criteria) {
        return criteria.showTable;
      }
    },
    displayCount() {
      return this.dataCount || 0;
    }
  }
};